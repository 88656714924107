import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";
import dynamicExports from "./envConfig";

const createMsalConfig = (value: string): Configuration | null => {
    const config = dynamicExports[`${value}_CONFIG`];
    if (!config) {
        return null;
    }

    return {
        auth: {
            clientId: config.clientId,
            authority: config.authority,
            redirectUri: config.redirectUri,
            postLogoutRedirectUri: config.postLogoutRedirectUri,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: false,
        },
    };
};

const msalConfigs: Record<string, Configuration> = Object.keys(dynamicExports).reduce((configs, key) => {
    const value = key.replace("_CONFIG", "");
    const config = createMsalConfig(value);
    if (config) {
        configs[value] = config;
    }
    return configs;
}, {} as Record<string, Configuration>);

export const getMsalConfig = (key: string): Configuration | null => msalConfigs[key] || null;

export const scopes = ["User.Read"];

export const loginRequest = {
    scopes,
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const msalStorage = (key: string): Storage =>
    getMsalConfig(key)?.cache?.cacheLocation === "sessionStorage" ? sessionStorage : localStorage;
