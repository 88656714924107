import { useSelector } from "react-redux";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AppRoutes from './routes';
import MsalAdConfig from './components/msalAdConfig';
import { PageLayout } from './components/layout';
import { getMsalConfig } from './config/msalConfig'; // Replace with the correct path to your dynamic config file
import './styles/App.css';

function App() {
  const { msalAd } = useSelector(({ msal }) => msal);
  let msalInstance: PublicClientApplication | null = null;

  if (msalAd) {
    const config = getMsalConfig(msalAd); // Dynamically fetch the configuration
    if (config) {
      msalInstance = new PublicClientApplication(config);
    }
  }

  return (
    <>
      {
        !msalInstance &&
        <div className="app_wrapper">
          <MsalAdConfig />
        </div>
      }
      {msalInstance &&
        <MsalProvider instance={msalInstance}>
          <PageLayout>
            <AppRoutes />
          </PageLayout>
        </MsalProvider>
      }
    </>
  );
}

export default App;
