import { adsConfig } from "./AdsConfig";

const dynamicExports = {};

adsConfig.forEach(({ value }) => {
    const clientIdKey = `REACT_APP_${value}_MSAL_CLIENT_ID`;
    const authorityKey = `REACT_APP_${value}_MSAL_AUTHORITY`;
    const redirectUriKey = `REACT_APP_${value}_MSAL_REDIRECT_URI`;
    const postLogoutRedirectUriKey = `REACT_APP_${value}_MSAL_POST_LOGOUT_REDIRECT_URI`;

    if (
        process.env[clientIdKey] &&
        process.env[authorityKey] &&
        process.env[redirectUriKey] &&
        process.env[postLogoutRedirectUriKey]
    ) {
        dynamicExports[`${value}_CONFIG`] = {
            clientId: process.env[clientIdKey],
            authority: process.env[authorityKey],
            redirectUri: process.env[redirectUriKey],
            postLogoutRedirectUri: process.env[postLogoutRedirectUriKey],
        };
    }
});

export default dynamicExports;

export const {
    REACT_APP_EMP_CODE,
    REACT_APP_BLUE_CODE,
    REACT_APP_MS_URL,
    REACT_APP_GET_BLUE,
    REACT_APP_POST_EMPLOYEE,
    REACT_APP_GET_EMPLOYEE,
    REACT_APP_API_KEY,
    REACT_APP_ENCRYPTION_KEY,
    REACT_APP_ENCRYPTION_ALGORITHM,
    REACT_APP_ENCRYPTION_IV,
    REACT_APP_JV_ENABLE
} = process.env;
