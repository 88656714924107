import styles from './Form.module.scss';
import React, { useEffect, useState } from 'react';
import { GET_BLUE_DETAILS, X_API_KEY_BLUE } from '../../config/apiConfig';
import appStore from '../../images/icons/appStore.png';
import qrcode from '../../images/icons/qrcode.png';
import googlePlay from '../../images/icons/googlePlay.png';
import combined from '../../images/icons/combined.png';
import circleWarning from '../../images/icons/Circle_Warning.png';
import verifiedIcon from '../../images/icons/verifiedIcon.png';
import axiosClient from '../../Services/http_service';
import { encryptData } from 'src/utils/crypto';

export const Form = (props: any) => {
    const { formData, validStatus, errorMsg = null, setErrorMsg, optionsState } = props;
    const [validBtn, setValidBtn] = useState<number>(validStatus);
    const [notBlueMember, setNotBlueMember] = useState<Boolean>(true);
    const [blueErrMsg, setBlueErrMsg] = useState('');


    useEffect(() => {
        setValidBtn(validStatus);
    }, [validStatus]);


    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        let mobileNumber = (document.getElementById('mobNo') as HTMLInputElement).value;
        let localeId = (document.getElementById('localeId') as HTMLInputElement).value;
        let empId = (document.getElementById('empId') as HTMLInputElement).value;
        if (validBtn === 0) {
            if (mobileNumber) {
                axiosClient
                .get(`${GET_BLUE_DETAILS}?phoneNumber=${encodeURIComponent(encryptData(localeId+mobileNumber.replaceAll("+", "")))}`,)
                    .then((response) => {

                        if (response?.data?.messages[0]?.status === 'success') {
                            if (response?.data?.employee?.employeeId !== "" && response?.data?.employee?.employeeId !== empId) {
                                setNotBlueMember(false);
                                setValidBtn(2);
                                setBlueErrMsg("Phone already linked with other employee");
                            } else {
                                setValidBtn(1);
                                props.getBlueData(response?.data);
                            }
                        } else {
                            setNotBlueMember(true);
                            setValidBtn(2);
                            setBlueErrMsg(response?.data?.messages[0]?.message);
                        }
                    })
                    .catch((error) => {
                        setValidBtn(2);
                    });
            } else {
                setBlueErrMsg('Please enter Mobile Number');
            }
        }
    };
    const getText = () => {
        if (validBtn === 1) {
            return 'Verified'
        } else if (validBtn === 2) {
            return 'Invalid'
        } else return 'Verify';
    }
    const tandc = () => {
        let e = document.getElementById("localeId") as any;
        let text = e[e.selectedIndex].text.replace(/ *\([^)]*\) */g, "");       
        // navigate('/termsAndCondition', {
        //     state: {
        //         locale: text
        //     }
        // });
        return `/termsAndCondition#${text}`
    }
    const handleMobChange = (e) => {
        setValidBtn(0);
        setBlueErrMsg("");
        props?.handleChange(e, true);
    }
    const handleIdChange = (e) => {
        e.preventDefault();
        setErrorMsg(null);
        props?.handleChange(e);
    }
    return (
        <>
            <form className={styles.formBdy} onSubmit={handleSubmit}>
                {props?.showFields[0]?.email.show ?
                    <div className={styles.fieldWrapper}>
                        <label className={styles.lblTxt} htmlFor="email">Employee Email ID</label>
                        <input className={styles.inpTxt} type="email" id="email" name="email" value={formData.email} onChange={props?.handleChange} disabled={props?.showFields[0]?.email?.disabledStatus} />
                    </div>
                    : null}
                {props?.showFields[0]?.empId.show ?
                    <div className={styles.fieldWrapper}>
                        <label className={styles.lblTxt} htmlFor="empId">Employee ID *</label>
                        <input className={`${styles.inpTxt}`} 
                            type="text" 
                            id="empId" 
                            name="empId"
                            maxLength={8}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} 
                            value={formData.empId} 
                            onChange={(e)=>{handleIdChange(e)}} 
                            disabled={props?.showFields[0]?.empId?.disabledStatus} required />
                            {
                                !props?.showFields[0]?.empId?.disabledStatus && 
                                 <label style={{color:'red'}}  >Note: Should be provide without "00" i.e. 123456 </label>
                            }
                           
                        {/* <div className={`${styles.errorText} ${formData.empId.length ? '' : styles.show}`}>
                            <img src={circleWarning} alt="Warning"/>
                            <span>Please enter employee Id</span>
                        </div> */}
                        {errorMsg?
                            <div className={`${styles.errorText} ${styles.show}`}>
                                <img src={circleWarning} alt="Warning" />
                                <span>{errorMsg}</span>
                            </div>
                            : null}
                    </div>
                    : null}
                {props?.showFields[0]?.name.show ?
                    <div className={styles.fieldWrapper}>
                        <label className={styles.lblTxt} htmlFor="name">Employee Name</label>
                        <input className={styles.inpTxt} type="text" id="name" name="name" value={formData.name} onChange={props?.handleChange} disabled={props?.showFields[0]?.name?.disabledStatus} />
                    </div>
                    : null}
                {props?.showFields[0]?.mobNo.show ?
                    <div className={styles.fieldWrapper}>
                        <div>
                            <label className={styles.lblTxt} htmlFor="mobNo">Employee Blue Mobile Number</label>
                            <select id="localeId" 
                                onChange={handleMobChange} 
                                disabled={props?.showFields[0]?.mobNo?.disabledStatus}
                                className={`${styles.slctList} ${styles.verifyBtn}`}
                                >
                                <option selected={optionsState === 'UAE'} value="971">UAE (971)</option>
                                <option selected={optionsState === 'KSA'} value="966">KSA (966)</option>
                                <option selected={optionsState === 'Oman'} value="968">Oman (968)</option>
                                <option selected={optionsState === 'Kuwait'} value="965">Kuwait (965)</option>
                                <option selected={optionsState === 'Singapore'} value="65">Singapore (65)</option>
                                <option selected={optionsState === 'Malaysia'} value="60">Malaysia (60)</option>
                                <option selected={optionsState === 'Bahrain'} value="973">Bahrain (973)</option>
                                <option selected={optionsState === 'Egypt'} value="20">Egypt (20)</option>
                                <option selected={optionsState === 'Qatar'} value="974">Qatar (974)</option>
                                <option selected={optionsState === 'Hong Kong'} value="852">Hong Kong (852)</option>
                            </select>
                            <input placeholder="565256XXX"
                                className={`${styles.inpTxt} ${styles.inpTxtShort} `}
                                id="mobNo"
                                name="mobNo"
                                type="text"
                                maxLength={10}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                }}
                                value={formData.mobNo}
                                onChange={handleMobChange}
                                disabled={props?.showFields[0]?.mobNo?.disabledStatus} />
                            {props?.showFields[0]?.submitBtn.show ?
                                <button className={`
                                ${styles.submitBtn} 
                                ${validBtn === 1 ? 'validBtnIcon' : ''}
                                ${validBtn === 2 ? 'invalidBtnIcon' : ''}
                            `} type="submit">{getText()}
                                    {validBtn === 1 ? <img className={styles.vIcon} src={verifiedIcon} alt="VerifiedIcon" /> : ''}
                                </button> : null}
                        </div>

                        {/* <div className={`${styles.errorText} ${formData.mobNo.length ? '' : styles.show}`}>
                            <img src={circleWarning} alt="Warning"/>
                            <span>Please enter employee blue mobile number</span>
                        </div> */}
                        {blueErrMsg ?
                            <div className={`${styles.errorText} ${styles.show}`}>
                                <img src={circleWarning} alt="Warning" />
                                <span>{blueErrMsg}</span>
                            </div>
                            : null}
                        {props?.showFields[0]?.submitBtn.disabledStatus ?
                            <div className={`${styles.errorText} ${styles.show}`}>
                                <img src={circleWarning} alt="Warning" />
                                <span>Employee already linked with BLUE</span>
                            </div>
                            : null}
                    </div>
                    : null}
                {props?.showFields[0]?.consent.show ?
                    <div className={`${styles.consentWrapper} ${styles.fieldWrapper}`}>
                        <input className={`${styles.inpTxt} ${styles.consent}`} type="checkbox" id="consent" name="consent" onChange={props?.validateForm} />
                        <label className={`${styles.lblTxt} ${styles.consentLbl}`} htmlFor="consent">
                            <p><span>I have read and I agree to the </span>
                                <a href={tandc()} rel="noreferrer" className={styles.link} target="_blank">Terms and Conditions</a>
                                <span> and the </span> <a href="https://www.alfuttaim.com/privacy-policy/" rel="noreferrer" target="_blank" className={styles.link}>Privacy Policy</a></p>
                        </label>
                    </div>
                    : null}
            </form>

            <div className={`${styles.invalidBanner} ${validBtn === 2 && notBlueMember ? 'showBanner' : ''}`}>
                <div className={styles.textWrap}>
                    <p>Not a Blue member?</p>
                    <span>Download the app, create an account and enter the mobile number here to view your employee exclusive offers on Blue!</span>
                </div>
                <img src={qrcode} alt="QR Code" className={styles.iconQr} />
                <div className={styles.iconWrap}>
                    <img src={appStore} alt="App Store" className={styles.icon} />
                    <img src={googlePlay} alt="Google Play" className={styles.icon} />
                    <img src={combined} alt="App Store" className={styles.iconCombined} />
                </div>
            </div>
        </>
    );
};
