import { useSelector } from "react-redux";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AppRoutes from './routes';
import MsalAdConfig from './components/msalAdConfig';
import { PageLayout } from './components/layout';
import { afgMsalConfig } from "./config/AfgMsalConfig";
import { disMsalConfig } from "./config/DisMsalConfig";
import { uasMsalConfig } from "./config/UasMsalConfig";
import { eibMsalConfig } from "./config/EibMsalConfig";
import './styles/App.css';

function App() {
  const { msalAd } = useSelector(({ msal }) => msal);
  let msalInstance: PublicClientApplication | null = null;

  if (msalAd) {
    let config = null;
    switch (msalAd) {
      case 'AFG':
        config = afgMsalConfig
        break;
      case 'DIS':
        config = disMsalConfig;
        break;
      case 'UAS':
        config = uasMsalConfig;
        break;
      case 'EIB':
        config = eibMsalConfig;
        break;
      case 'DC-AVIATION':
        config = afgMsalConfig;
        break;
      default:
    }
    if (config) {
      msalInstance = new PublicClientApplication(config);
    }
  }

  return (
    <>
      {
        !msalInstance &&
        <div className="app_wrapper">
          <MsalAdConfig />
        </div>
      }
      {msalInstance &&
        <MsalProvider instance={msalInstance}>
          <PageLayout>
            <AppRoutes />
          </PageLayout>
        </MsalProvider>
      }
    </>
  );
}

export default App;
