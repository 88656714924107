import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { setMsalAd } from '../../store/slices/MsalSlice';
import { Breadcrumb } from '../Breadcrumb';
import { adsConfig } from '../../config/AdsConfig';
import selectedIcon from '../../images/icons/Circle_Check.png';
import { REACT_APP_JV_ENABLE } from '../../config/envConfig'
import { parseString } from 'src/utils/claimUtils';
const parsedJvEnableMap  = parseString(REACT_APP_JV_ENABLE)

const MsalAdConfig: FC = () => {
    const dispatch = useDispatch();
    const { msalAd } = useSelector(({ msal }) => msal);
    const [activeAD, setActiveAD] = useState(msalAd);
    const [selectedDropdown, setSelectedDropdown] = useState<string>('none');  // Start with "none" selected

    const submitAD = () => {
        dispatch(
            setMsalAd(activeAD)
        );
    }
    const adsConfigMap = adsConfig.filter(v=> {
        return parsedJvEnableMap[v.value]
    })

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        setSelectedDropdown(selectedValue);
        setActiveAD(selectedValue === 'none' ? msalAd : selectedValue);
    };

    // Only show ads if a specific employer is selected
    const displayedAds = selectedDropdown === 'none'
        ? []
        : adsConfigMap.filter(adConfig => adConfig.value === selectedDropdown);

    return (
        <div className="wrapper">
            <p className="heading">Link your employee account to Blue</p>
            <Breadcrumb highlight="1" />

            {/* Dropdown to select employer */}
            <div className="dropdown-wrapper">
                <div className="dropdown-container">
                    <select value={selectedDropdown} onChange={handleSelectChange} className="dropdown">
                        <option value="none">Select Your Company</option>
                        {adsConfigMap.map((adConfig, idx) => (
                            <option key={idx} value={adConfig.value}>
                                {adConfig.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Box elements populated based on dropdown selection */}
            <div className="boxWrapper">
                {displayedAds.map((adConfig, idx) => (
                    <div
                        className={`box ${activeAD === adConfig.value ? 'active' : ''}`}
                        key={idx}
                        onClick={() => setActiveAD(adConfig.value)}
                    >
                        <span className="selectedIcon">
                            <img src={selectedIcon} alt="Selected" />
                        </span>
                        {adConfig.name}
                    </div>
                ))}
            </div>
            <div className="mob-bottom-wrapper">
                <Button onClick={submitAD} className="custom-btn">Next</Button>
            </div>
        </div>
    );
}

export default MsalAdConfig;